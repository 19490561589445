import request from "@/api/axios";

export function appAccess(alias) {
    //按上架状态分类查询应用列表
    return request({
        url: `/admin/app/access/alias/${alias}`,
        method: "get"
    });
}


const routeAuth =async (alias) =>{
    return new Promise((resolve, reject) => {
         appAccess(alias).then(res=>{
            const result =  res.data.data //字段判断

            if (result.userIsGod){
                resolve(true) //放行
            }

            if (!result.result) return  reject(false)//禁用
            if (result.result!=='access') return  reject(false)//禁用
            if (result.memberStatus !== 0 && result.memberStatus !==null) return  reject(false)//禁用
            if (result.userStatus !== 0) return  reject(false)//禁用

            resolve(true) //放行

        }).catch(err=>{
            reject(false) //禁用
        })
    })
}

export {
    routeAuth
}
