import { createRouter, createWebHistory } from "vue-router";
import {routeAuth} from '@/util/auth'
const basename = "";

const routes = [
  {
    path: "/",
    redirect: "/personalPhotoes",
  },
  {
    path: '/403',
    name: '403',
    component: () => import('../views/Error/403.vue'),
  },
  {
    path: "/personalPhotoes",
    name: "个人相册",
    component: () => import(/* webpackChunkName: "about" */ "@/views/personalPhotoes/index.vue"),
  },
  {
    path: "/classPhotoes",
    name: "班级相册",
    component: () => import(/* webpackChunkName: "about" */ "@/views/classPhotoes/index.vue"),
  },
];

//权限检测
let auth = true
routeAuth('classPhoto').then( res=>{
  auth = true //权限正常
}).catch(async err=>{
  auth = false  //无权限
  window.$wujie?.props.toggleLeftBar(false)
  router.replace('/403')
})

const router = createRouter({
  history: createWebHistory(basename),
  routes,
});

router.beforeEach((to,from,next)=>{
  window.$wujie?.props.toggleLeftBar(true)
  if (to.path === '/login') next()
  if (to.path === '/403') next()
  if (to.path === '/404') next()
  if (!auth){
    window.$wujie?.props.toggleLeftBar(false)
    next({path:'/403'})
  }
  next()
})

export default router;
