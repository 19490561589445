import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useRouter } from "vue-router";
/** icon */
import { Icon } from 'tdesign-icons-vue-next';
export default {
  __name: 'App',
  setup(__props) {
    var _window$$wujie;
    const router = useRouter();
    (_window$$wujie = window.$wujie) === null || _window$$wujie === void 0 ? void 0 : _window$$wujie.bus.$on("routeChange", path => {
      router.push(path);
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_unref(Icon), {
        class: "no-display"
      }), _createVNode(_component_router_view)], 64);
    };
  }
};