import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { getHostNameKey } from './env';
import "./index.css";
import "./common.scss";
import './style/font.scss'

import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';
import "./theme.css";

const app = createApp(App)
app.use(router)
app.use(TDesign)
app.mount("#app");
app.config.globalProperties.$host = getHostNameKey()
