const host = {
  dev: 'devmain.huixuecloud.cn',
  test: 'testmain.huixuecloud.cn',
  pre: 'premain.huixuecloud.cn',
  main: 'main.huixuecloud.cn',
}

// 项目部署环境
const envKey = ['dev', 'test', 'pre', 'main']

// 当前host
const curHost = window.location.host

// 获取域名标识
export const getHostNameKey = () => {
  let nameKey = 'dev'
  Object.keys(host).forEach(val => {
    if (curHost.indexOf(host[val]) > -1) {
      nameKey = val
    }
  })
  return nameKey
}

