import { request, instance } from 'hxhyaxios'

instance.interceptors.request.use(
  (config) => {
    const token = window.$wujie?.props.access_token;
    // const token = '9ba9c9e1-6a18-4bc0-b74e-2adb68fcff81'
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    //对请求错误做点什么
    return Promise.reject(error);
  }
)

export default request;
